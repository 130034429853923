import React from 'react';
import Password from '../../../components/Account/Password';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const PasswordPageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute path="/en-AU/account/password" component={Password} />
  </ContextProvider>
);

export default PasswordPageEnAu;
